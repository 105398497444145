<template>
  <div class="home">
    <img
      src="@/assets/img/placeholder.jpeg"
      class="img-fluid"
      alt="placeholder"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "home",
  computed: mapState(["user"]),
  mounted() {
    this.$router.push("/");
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-height: 100vh;
  background-color: #bbbbbb;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
  }
}
</style>
